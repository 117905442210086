import axios from 'axios'

const apiService = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL + '/members/'
})

// Add a request interceptor to attach the token to the headers
apiService.interceptors.request.use(
  (config) => {
    // Attach token to the header if it exists
    config.headers['x-memberstack-token'] = window.localStorage.getItem('_ms-mid')
    config.headers['x-bruqi-class-name'] = window.localStorage.getItem('_bq-cn')
    if (window.localStorage.getItem('_bq-va')) {
      config.headers['x-bruqi-view-as'] = window.localStorage.getItem('_bq-va')
    }
    return config
  },
  // Handle request error
  error => Promise.reject(error)
)

// Add a response interceptor to return response.data directly
apiService.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error?.response?.data || error)
)

export default apiService
