import Parse from 'parse/dist/parse.min.js'
const serverURL = import.meta.env.VITE_API_BASE_URL + '/parse/'
Parse.initialize(import.meta.env.VITE_APP_ID, import.meta.env.VITE_JS_KEY)
Parse.serverURL = serverURL

export default Parse

export const getExportUrl = (uri, params = {}) => {
  const url = new URL(import.meta.env.VITE_API_BASE_URL + '/exports/' + uri)
  const user = Parse.User.current()
  for (const key in params) {
    if (params[key]) {
      url.searchParams.append(key, params[key])
    }
  }
  user && url.searchParams.append('sid', user.getSessionToken())
  return url.toString()
}
