import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'
import posthog from '@/services/posthog'
import pinia from '@/store'
import resolveRouter from '@/router'
import filters from '@/filters'
import { useSnackbarsStore } from '@/store/snackbars'
import App from '@/App.vue'
import '@/styles/index.scss'

const initApp = async () => {
  const app = createApp(App)
  registerPlugins(app)

  app.use(pinia)

  const router = await resolveRouter()
  app.use(router)

  app.provide('posthog', posthog)

  app.config.globalProperties.$filters = filters

  const snackbars = useSnackbarsStore()
  app.provide('snackbars', snackbars)

  app.mount('#app')
}

initApp()
