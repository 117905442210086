// Utilities
import { defineStore } from 'pinia'
import Parse from '@/services/parse'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    initialized: null,
    user: Parse.User.current(),
    users: [],
    counts: {},
    statuses: {}
  }),
  getters: {
    isSuperAdmin: (state) => state.user.get('accType') === 'superadmin',
    isAdmin: (state) => state.user.get('accType')?.includes('admin')
  },
  actions: {
    async initialize() {
      const [users, counts] = await Parse.Cloud.run('initialize-admin')
      this.users = users
      this.counts = counts
      this.initialized = new Date()
      try {
        this.statuses = await Parse.Cloud.run('admin-status')  
      } catch (error) {
        console.error(error)
      }
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      await Parse.User.logOut()
      window.location.href = '/'
    }
  },
})
