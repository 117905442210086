<template>
  <v-app :class="{ mobile: $vuetify.display.smAndDown }">
    <router-view />
    <snackbars />
    <reload-prompt />
  </v-app>
</template>

<script setup>
onMounted(() => {
  document.getElementsByTagName('html')[0]?.classList?.remove('loading')
})

</script>
