import * as Sentry from '@sentry/vue'
import posthog from '@/services/posthog'
import Parse from 'parse/dist/parse.min.js'
import { mode } from '@/config'
export const registerErrorHandler = (app) => {
  Sentry.init({
    app,
    environment: mode,
    dsn: 'https://d540764b2dcd9d4e48b47daf004b4974@o4507210930585600.ingest.de.sentry.io/4507210931830864',
    trackComponents: true,
    // ignoreErrors: [
    //   'Non-Error exception captured',
    //   'Object captured as exception with keys: code, message'
    // ],
    integrations: [
      posthog.sentryIntegration({
        organization: 'bruqi',
        projectId: '4507210931830864',
        severityAllowList: ['error', 'info'] // optional: here is set to handle captureMessage (info) and captureException (error)
      }),
      Sentry.browserTracingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: mode === 'production' ? 0.05 : 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [import.meta.env.VITE_API_BASE_URL],
  })

  const handleError = (error) => {
    if (!error) { return true }
    if (error?.code === Parse.Error.INVALID_SESSION_TOKEN) {
      window.localStorage.clear()
      window.location.reload()
      return true
    }
    const message = error?.message || error?.error || error
    if (message.startsWith('ResizeObserver')) { return true }
    // deprecate after PWA is released
    // the following errors all have to do with vite updating source file hashes and the previous versions js files not being found.
    // if (
    //   message.includes('Failed to fetch dynamically imported module') ||
    //   message.includes('Importing a module script failed') ||
    //   message.includes('valid JavaScript MIME type')
    // ) {
    //     return window.location.reload()
    // }
    if (mode !== 'development') {
      Sentry.captureException(error)
    } else {
      // eslint-disable-next-line
      console.error(error)
      window.$error = error
    }
    return true
  }
  // error event: This event is triggered when an error occurs in the global scope, such as syntax errors in scripts, failed network requests, or runtime errors that are not caught by try-catch blocks.
  window.addEventListener('error', event => handleError(event.error))
  // unhandledrejection event: This event is triggered when a promise is rejected, but there is no corresponding .catch() handler to handle the rejection. This typically occurs when a promise is rejected but no .catch() or .then() with a second argument (for handling rejections) is attached to the promise chain.
  window.addEventListener('unhandledrejection', ({ reason: error }) => handleError(error))
  return handleError
}

export default registerErrorHandler